<template>
  <div class="min-h-screen bg-gray-100 flex flex-col">
    <header class="bg-blue-600 text-white p-4 shadow-md">
      <h1 class="text-2xl font-bold">CoolCat的专属对话</h1>
    </header>

    <div class="flex-grow flex flex-col p-4 sm:p-6 md:p-8 max-w-full sm:max-w-4xl mx-auto w-full">
      <div class="flex-grow bg-white rounded-lg shadow-md overflow-hidden flex flex-col">
        <div class="flex-grow overflow-y-auto p-4" ref="chatContainer">
          <div v-for="(message, index) in conversation" :key="index" class="mb-4">
            <div :class="{'text-right': message.role === 'user'}">
              <div :class="{
                'bg-blue-500 text-white': message.role === 'user',
                'bg-gray-300 text-gray-800': message.role === 'assistant'
              }" 
                  class="inline-block px-4 py-2 rounded-lg max-w-3/4 break-words">
                <div v-if="message.role === 'assistant'" v-html="formatMessage(message.content)"></div>
                <div v-else>{{ message.content }}</div>
              </div>
            </div>
          </div>
          <div v-if="isTyping" class="text-gray-500">Claude 正在输入...</div>
        </div>

        <div class="border-t p-4">
          <textarea v-model="userInput" @keyup.enter.prevent="sendMessage" 
                    class="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none resize-none" 
                    rows="3" placeholder="在这里输入您的问题..."></textarea>
          <div class="mt-4 flex justify-between">
            <button @click="resetConversation" 
                    class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
              重置对话
            </button>
            <button @click="sendMessage" 
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
              发送
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, nextTick } from 'vue'
import hljs from 'highlight.js'
import 'highlight.js/styles/atom-one-dark.css'

export default {
  name: 'App',
  setup() {
    const conversation = ref([])
    const userInput = ref('')
    const chatContainer = ref(null)
    const isTyping = ref(false)

    const formatMessage = (content) => {
      const codeBlockRegex = /```(\w+)?\n([\s\S]*?)```/g;
      let codeBlockId = 0;
      content = content.replace(codeBlockRegex, (match, language, code) => {
        const highlightedCode = language
          ? hljs.highlight(code.trim(), { language }).value
          : hljs.highlightAuto(code.trim()).value;
        
        const lines = highlightedCode.split('\n');
        const numberedLines = lines.map((line, index) => 
          `<span class="code-line"><span class="line-number">${index + 1}</span><span class="code-content">${line}</span></span>`
        ).join('');

        codeBlockId++;
        return `
          <div class="code-block-wrapper">
            <span class="language-label">${language || 'code'}</span>
            <button class="copy-button" onclick="copyCode('codeBlock${codeBlockId}')">
              复制
            </button>
            <pre><code id="codeBlock${codeBlockId}" class="hljs ${language || ''}">${numberedLines}</code></pre>
          </div>
        `;
      });

      // 处理行内代码
      content = content.replace(/`([^`\n]+)`/g, '<code class="inline-code">$1</code>');

      // 处理段落
      content = content.replace(/(?<=\n|^)(?!<h3|<div class="code-block-wrapper"|<p)(.*?)(?=\n|$)/g, (match, p1) => {
        // 如果段落只包含行内代码，不添加额外的换行
        if (p1.trim().startsWith('<code class="inline-code">') && p1.trim().endsWith('</code>')) {
          return `<p>${p1}</p>`;
        }
        // 否则在段落后添加一个换行
        return `<p>${p1}</p>\n`;
      });

      return content;
    };

    const sendMessage = async () => {
      if (userInput.value.trim()) {
        const userMessage = { role: 'user', content: userInput.value.trim() }
        conversation.value.push(userMessage)
        userInput.value = ''
        isTyping.value = true

        await nextTick()
        scrollToBottom()

        try {
          await callClaudeAPI([...conversation.value])
        } catch (error) {
          console.error('Error calling Claude API:', error)
          conversation.value.push({ role: 'assistant', content: '抱歉，发生了一个错误。请稍后再试。' })
        } finally {
          isTyping.value = false
          await nextTick()
          scrollToBottom()
        }
      }
    }

    const resetConversation = () => {
      conversation.value = []
    }

    const scrollToBottom = () => {
      if (chatContainer.value) {
        chatContainer.value.scrollTop = chatContainer.value.scrollHeight
      }
    }

    const callClaudeAPI = async (conversationHistory) => {
  const apiUrl = 'https://odd-butterfly-306e.wei-hao2031.workers.dev/v1/v1/messages'
  const apiKey = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  const requestBody = {
    model: "claude-3-5-sonnet",
    system: "You are a helpful assistant.",
    messages: conversationHistory,
    max_tokens: 2048,
    temperature: 0.7,
    top_p: 0.9,
    stream: true
  }

  try {
    console.log('Sending request to API...', requestBody)
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': apiKey
      },
      body: JSON.stringify(requestBody)
    })

    if (!response.ok) {
      const errorBody = await response.text()
      console.error('Error response body:', errorBody)
      throw new Error(`HTTP error! status: ${response.status}, body: ${errorBody}`)
    }

    const reader = response.body.getReader()
    const decoder = new TextDecoder()
    let assistantMessage = { role: 'assistant', content: '' }
    conversation.value.push(assistantMessage)

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value } = await reader.read()
      if (done) break

      const chunk = decoder.decode(value)
      const lines = chunk.split('\n')
      
      for (const line of lines) {
        if (line.startsWith('data:')) {
          try {
            const data = JSON.parse(line.slice(5))
            if (data.type === 'content_block_delta' && data.delta.type === 'text_delta') {
              assistantMessage.content += data.delta.text
              await nextTick()
              scrollToBottom()
            } else if (data.type === 'message_stop') {
              return // 结束流式响应处理
            }
          } catch (e) {
            console.error('Error parsing JSON:', e)
          }
        }
      }
    }
  } catch (error) {
    console.error('Error calling Claude API:', error)
    throw error
  }
}

    onMounted(() => {
      scrollToBottom()
      window.copyCode = (elementId) => {
        const codeElement = document.getElementById(elementId);
        if (codeElement) {
          // 创建一个临时元素来存放代码
          const tempElement = document.createElement('div');
          // 复制代码元素的内容
          tempElement.innerHTML = codeElement.innerHTML;
          // 移除所有的行号元素
          tempElement.querySelectorAll('.line-number').forEach(el => el.remove());
          // 获取纯文本内容
          const code = tempElement.textContent || tempElement.innerText;
          // 复制到剪贴板
          navigator.clipboard.writeText(code).then(() => {
            alert('代码已复制到剪贴板');
          }).catch(err => {
            console.error('无法复制代码: ', err);
          });
        }
      };
    })

    return {
      conversation,
      userInput,
      sendMessage,
      resetConversation,
      chatContainer,
      isTyping,
      formatMessage
    }
  }
}
</script>

<style>
.overflow-y-auto::-webkit-scrollbar {
  width: 8px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.code-block-wrapper {
  position: relative;
  margin: 0.5em 0;
  border-radius: 2px;
  overflow: hidden;
}

pre {
  background-color: #282c34;
  margin: 1em 0; /* 增加上下边距 */
  padding: 1em 0.5em 0.5em 0; /* 增加上部padding */
  overflow-x: auto;
  line-height: 1.4; /* 稍微增加行高 */
}

code {
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.9em;
  display: block;
}

.code-line {
  display: block;
  line-height: 1.4;
  position: relative;
  padding-left: 3em; /* 为行号留出空间 */
}

.inline-code {
  background-color: #f0f0f0;
  color: #e53e3e;
  padding: 2px 4px;
  border-radius: 3px;
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.9em;
  white-space: nowrap;
  display: inline-block;
  margin: 0 2px;
}
.line-number {
  position: absolute;
  left: 0;
  width: 2em;
  color: #606366;
  text-align: right;
  padding-right: 0.5em;
  border-right: 1px solid #404040;
  user-select: none;
  font-size: 0.9em;
}

/* 覆盖 highlight.js 的默认样式 */
.hljs {
  padding: 0 !important;
  background: transparent !important;
}

/* 复制按钮样式 */
.copy-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #4a4a4a;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 0.8em;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.copy-button:hover {
  opacity: 1;
}

/* 语言标签样式 */
.language-label {
  position: absolute;
  top: 5px;
  left: 5px;
  background-color: #4a4a4a;
  color: white;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 0.8em;
  opacity: 0.7;
}

.code-content {
  white-space: pre-wrap; /* 保留空白，但允许自动换行 */
}
</style>